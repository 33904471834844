import React, { useEffect, useState, createContext } from "react";
// import UserIcon from "../../../assets/images/user.png";
import Api from "../../../config/Api";
// import { useDispatch, useSelector } from "react-redux";
// import { setAuth } from "../../../Reducers/Authentication";
import { toast } from "react-toastify";
import {
  useForm,
  Controller,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { BiMap } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import GoogleMap from "./Components/GoogleMap";
// import { useSelector } from "react-redux";
import DamageItemRow from "./Components/DamageItemRow";
import { FiPlus } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";

export const DamageContext = createContext({
  remove: () => {},
  append: () => {},
});

const Sdrf2 = () => {
  const methods = useForm({
    defaultValues: {
      damages: [
        {
          first_damage_classification_id: "",
          second_damage_classification_id: "",
          third_damage_classification_id: "",
          qty: "",
          owner_type: "",
        },
      ],
    },
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = methods;

  const { append, remove, fields } = useFieldArray({
    control,
    name: "damages",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { id, isEdit } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [disasterList, setDisasterList] = useState([]);
  const [isMapModalShow, setIsMapModalShow] = useState(false);
  const mapModalClose = () => setIsMapModalShow(false);
  const mapModalShow = () => setIsMapModalShow(true);
  // eslint-disable-next-line
  const [isFetching, setIsFetching] = useState(false);
  const [damageLevel1List, setDamageLevel1List] = useState([]);
  const [detailData, setDetailData] = useState([]);

  const level1Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.title,
          value: item.id,
          ...item,
        };
      });
  };

  const getlevel1 = async () => {
    try {
      setIsLoading(true);
      const res = await Api("first_damage_classification_get");
      // console.log(res);
      if (res.data.status === "1") {
        setDamageLevel1List(
          level1Option(res.data.DamageClassificationData.reverse())
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const YesNoRadio = ({ name, name2 }) => {
    return (
      <>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            {...register(name, {
              required: true,
            })}
            id={`${name}radio1`}
            value="Yes"
          />
          <label className="form-check-label" htmlFor={`${name}radio1`}>
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            {...register(name, {
              required: true,
              onChange: (e) => {
                clearTextBox(e, name2);
              },
            })}
            id={`${name}radio2`}
            value="No"
          />
          <label className="form-check-label" htmlFor={`${name}radio2`}>
            No
          </label>
        </div>
      </>
    );
  };

  const clearTextBox = (e, name) => {
    // console.log(name);
    if (name !== undefined) {
      if (e.target.value === "No") {
        if (name === "relief_fund") {
          setValue(`${name}_voucher_number`, "");
          setValue(`${name}_amount`, "");
        } else {
          setValue(`${name}_insurance_name`, "");
          setValue(`${name}_policy_number`, "");
        }
      }
    }
  };

  const InsurancePolicy = ({ name }) => {
    return (
      <div className="row">
        <div className="col-6">
          <label className="form-label">
            {name === "relief_fund" ? "Voucher No." : "Insurance Name"}
            <span className="text-danger fw-bold">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            {...register(
              name === "relief_fund"
                ? `${name}_voucher_number`
                : `${name}_insurance_name`,
              { required: true }
            )}
            disabled={isLoading}
          />

          {errors[
            name === "relief_fund"
              ? `${name}_voucher_number`
              : `${name}_insurance_name`
          ] && (
            <div className="error_line">
              {name === "relief_fund" ? "Voucher Number" : "Insurance Name"} is
              required
            </div>
          )}
        </div>
        <div className="col-6">
          <label className="form-label">
            {name === "relief_fund" ? "Amount" : "Policy Number"}
            <span className="text-danger fw-bold">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            {...register(
              name === "relief_fund"
                ? `${name}_amount`
                : `${name}_policy_number`,
              { required: true }
            )}
            disabled={isLoading}
          />

          {errors[
            name === "relief_fund" ? `${name}_amount` : `${name}_policy_number`
          ] && (
            <div className="error_line">
              {name === "relief_fund" ? "Amount" : "Policy Number"} is required
            </div>
          )}
        </div>
      </div>
    );
  };

  //getDisaster
  const getDisaster = async () => {
    try {
      setIsLoading(true);
      const res = await Api("nature_of_disaster_get");
      // console.log(res);
      if (res.data.status === 1) {
        setDisasterList(disasterListOption(res.data.NatureOfDisaster));
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDisaster

  const disasterListOption = (data) => {
    return data
      .filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
  };

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "" : "#fbfbfb",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  const addNewDamageField = (d) => {
    append(
      {
        first_damage_classification_id: "",
        second_damage_classification_id: "",
        third_damage_classification_id: "",
        qty: "",
        owner_type: "",
      },
      {
        shouldFocus: true,
      }
    );
  };

  //setDataInEdit
  const setDataInEdit = async () => {
    try {
      setIsFetching(true);
      const body = {
        id: id,
      };
      const res = await Api("view_applicant_details", "POST", body);

      if (res.data.status === 1) {
        const maindata = res.data.ApplicantDetails;
        // console.log(maindata);
        setDetailData(maindata);
        const Details = maindata.Details;
        const Damages = maindata.damages;
        let tempDamagesArray =
          Damages.length > 0
            ? Damages.map((a) => {
                return {
                  first_damage_classification_id: {
                    value: a.first_damage_classification.id,
                    label: a.first_damage_classification.title,
                    ...a.first_damage_classification,
                  },
                  second_damage_classification_id: {
                    value: a.second_damage_classification.id,
                    label: a.second_damage_classification.damage_name,
                    ...a.second_damage_classification,
                  },
                  third_damage_classification_id: {
                    value: a.third_damage_classification.id,
                    label: !!a.second_damage_classification.extent_unit
                      ? `${a.third_damage_classification.name} (${a.second_damage_classification.extent_unit})`
                      : a.third_damage_classification.name,
                    ...a.third_damage_classification,
                  },
                  qty: a?.qty,
                  damage_image:a?.damage_image,
                  certificate:a?.certificate,
                  owner_type: a?.owner_type,
                };
              })
            : [
                {
                  first_damage_classification_id: "",
                  second_damage_classification_id: "",
                  third_damage_classification_id: "",
                  qty: "",
                  owner_type: "",
                },
              ];
        // console.log(tempDamagesArray);

        const nature_disaster = maindata?.nature_disaster?.map((a) => {
          return {
            label: a.title,
            value: a.id,
            ...a,
          };
        });

        let tempResetObj = {
          date_of_occurrence: maindata?.Details?.date_of_occurrence,
          location_disaster: maindata?.Details?.location_disaster,
          latitude: maindata?.Details?.latitude,
          longitude: maindata?.Details?.longitude,
          nature_disaster: nature_disaster,
          damages: tempDamagesArray,
          deceased: Details?.application_extra_field?.deceased,
          risk_transfer_initiative:
            Details?.application_extra_field?.risk_transfer_initiative,
          damaged_insurance_policy: !!Details?.application_extra_field
            ?.damaged_insurance_policy
            ? Details?.application_extra_field?.damaged_insurance_policy
            : "",
          damaged_insurance_name:
            Details?.application_extra_field?.damaged_insurance_name !==
              "null" &&
            !!Details?.application_extra_field?.damaged_insurance_name
              ? Details?.application_extra_field?.damaged_insurance_name
              : "",
          damaged_policy_number:
            Details?.application_extra_field?.damaged_policy_number !==
              "null" &&
            !!Details?.application_extra_field?.damaged_policy_number
              ? Details?.application_extra_field?.damaged_policy_number
              : "",
          medical_insurance_policy: !!Details?.application_extra_field
            ?.medical_insurance_policy
            ? Details?.application_extra_field?.medical_insurance_policy
            : "",
          medical_insurance_name:
            Details?.application_extra_field?.medical_insurance_name !==
              "null" &&
            !!Details?.application_extra_field?.medical_insurance_name
              ? Details?.application_extra_field?.medical_insurance_name
              : "",
          medical_policy_number:
            Details?.application_extra_field?.medical_policy_number !==
              "null" &&
            !!Details?.application_extra_field?.medical_policy_number
              ? Details?.application_extra_field?.medical_policy_number
              : "",
          victim_insurance_policy: !!Details?.application_extra_field
            ?.victim_insurance_policy
            ? Details?.application_extra_field?.victim_insurance_policy
            : "",
          victim_insurance_name:
            Details?.application_extra_field?.victim_insurance_name !==
              "null" &&
            !!Details?.application_extra_field?.victim_insurance_name
              ? Details?.application_extra_field?.victim_insurance_name
              : "",
          victim_policy_number:
            Details?.application_extra_field?.victim_policy_number !== "null" &&
            !!Details?.application_extra_field?.victim_policy_number
              ? Details?.application_extra_field?.victim_policy_number
              : "",
          relief_fund: !!Details?.application_extra_field?.relief_fund
            ? Details?.application_extra_field?.relief_fund
            : "",
          relief_fund_voucher_number:
            Details?.application_extra_field?.relief_fund_voucher_number !==
              "null" &&
            !!Details?.application_extra_field?.relief_fund_voucher_number
              ? Details?.application_extra_field?.relief_fund_voucher_number
              : "",
          relief_fund_amount:
            Details?.application_extra_field?.relief_fund_amount !== "null" &&
            !!Details?.application_extra_field?.relief_fund_amount
              ? Details?.application_extra_field?.relief_fund_amount
              : "",
          comment:
            Details?.application_extra_field?.comment !== "null" &&
            !!Details?.application_extra_field?.comment
              ? Details?.application_extra_field?.comment
              : "",
        };
        // console.log(Boolean(Details?.application_extra_field?.comment));
        reset(tempResetObj);
        setIsFetching(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //setDataInEdit

  useEffect(() => {
    getDisaster();
    getlevel1();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (isEdit) {
      setDataInEdit();
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const onSubmit = async (d) => {
    console.log('d',d?.damages[2]?.certificate);
    try {
      setIsLoading(true);
      if (!id) {
        toast.error("Application ID Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return;
      }

      if (!d?.damages?.length || !d?.damages) {
        toast.error("Please add atleast one damage", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return;
      }
      // console.log("test");
      const formData = new FormData();
      formData.append("application_id", id);

      // console.log(d?.nature_disaster);
      // console.log(d.nature_disaster?.map((a) => a.value));
      if (d?.nature_disaster.length > 0) {
        d?.nature_disaster.forEach((item) => formData.append("nature_disaster[]", item.value))
      }

      formData.append("location_disaster", d?.location_disaster);
      formData.append("latitude", d.latitude);
      formData.append("longitude", d.longitude);


      for (var i = 0; i < d?.damages.length; i++) {
        if(d?.damages[i].image[0]){
        formData.append(`damages[${i}][damage_image]`, d?.damages[i].image[0]);
        }else{
          if(d?.damages[i].damage_old_image){
          const parts = d?.damages[i].damage_old_image?.split('/');
          const filenameWithQueryParams = parts[parts.length - 1];
          const [filename] = filenameWithQueryParams.split('?');
        //   console.log('damage_old_image', filename);
          formData.append(`damages[${i}][damage_old_image]`, filename);
          }
        }

        if(Boolean(d?.damages[i].certificate) && d?.damages[i].certificate != ""){
          formData.append(`damages[${i}][certificate]`, d?.damages[i].certificate[0]);
          }else{
              if(Boolean(d?.damages[i].certificate_old_image)){
              const parts = d?.damages[i].certificate_old_image?.split('/');
              const filename = parts[parts?.length - 1];
              formData.append(`damages[${i}][certificate_old_image]`, filename);
              }
          }

        formData.append(
          `damages[${i}][first_damage_classification_id]`,
          d?.damages[i].first_damage_classification_id.value
        );

        formData.append(
          `damages[${i}][second_damage_classification_id]`,
          d?.damages[i].second_damage_classification_id.value
        );

        formData.append(
          `damages[${i}][third_damage_classification_id]`,
          d?.damages[i].third_damage_classification_id.value
        );

        formData.append(
          `damages[${i}][qty]`,
          !!d?.damages[i].qty ? d?.damages[i].qty : 1
        );
        formData.append(`damages[${i}][owner_type]`, d?.damages[i].owner_type);
      }
      // console.log('formData ',Object.fromEntries(formData));
      // return
      formData.append("deceased", d?.deceased);
      formData.append("risk_transfer_initiative", d?.risk_transfer_initiative);
      formData.append("damaged_insurance_policy", d?.damaged_insurance_policy);
      formData.append("damaged_insurance_name", d?.damaged_insurance_name);
      formData.append("damaged_policy_number", d?.damaged_policy_number);
      formData.append("medical_insurance_policy", d?.medical_insurance_policy);
      formData.append("medical_insurance_name", d?.medical_insurance_name);
      formData.append("medical_policy_number", d?.medical_policy_number);
      if (d?.deceased === "Yes") {
        formData.append("victim_insurance_policy", d?.victim_insurance_policy);
        formData.append("victim_insurance_name", d?.victim_insurance_name);
        formData.append("victim_policy_number", d?.victim_policy_number);
      }
      formData.append("relief_fund", d?.relief_fund);
      formData.append(
        "relief_fund_voucher_number",
        d?.relief_fund_voucher_number
      );
      formData.append("relief_fund_amount", d?.relief_fund_amount);
      formData.append("comment", d?.comment);
      formData.append("date_of_occurrence", d.date_of_occurrence);
      // console.log(
      //   Boolean(d?.application_file) && Boolean(d?.application_file.length)
      // );
      if (Boolean(d?.application_file) && Boolean(d?.application_file.length)) {
        formData.append("application_file", d?.application_file[0]);
      }

      // console.log(Object.fromEntries(formData));
      // return
      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`);
      // }

      const res = await Api("save-application-second", "POST", formData);
      // console.log(res);

      if (res.data.status === 1) {
        setIsLoading(false);
        navigate("/sdrf-3", {
          state: {
            id: res.data.data,
            isEdit: isEdit,
          },
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-4">
        <div>
          <button className="btn btn-primary" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          </div>
          <div className="">
            <h5 className="text-dark">Damage Report</h5>
          </div>
        </div>
        <div className="steps_row mb-4">
          <ul>
            <li className="steps_box active">
              <div>
                <span className="steps_circle"></span>
                <p>Personal Details</p>
              </div>
            </li>
            <li className="steps_box active">
              <div>
                <span className="steps_circle"></span>
                <p>Damage Report</p>
              </div>
            </li>
            <li className="steps_box">
              <div>
                <span className="steps_circle"></span>
                <p>Authentication</p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <DamageContext.Provider value={{ remove }}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                encType="multipart/form-data"
              >
                <div className="row g-3">
                  <div className="col-lg-4 col-sm-6">
                    <label className="form-label">
                      Nature of Disaster{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="nature_disaster"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={style}
                          placeholder={"Select Nature of Disaster"}
                          options={disasterList}
                          closeMenuOnSelect={false}
                          isDisabled={isLoading}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          isMulti
                        />
                      )}
                    />
                    {errors.nature_disaster && (
                      <div className="error_line">
                        Nature of Disaster is required
                      </div>
                    )}
                  </div>
                  <div className="col-lg-8 col-sm-6">
                    <div className="d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <label className="form-label">
                          Location of Disaster{" "}
                          <span className="text-danger fw-bold">*</span>
                        </label>
                      </div>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={mapModalShow}
                          className="badge bg-info border-0"
                        >
                          <BiMap className="me-2" />
                          Choose on map
                        </button>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Location of Disaster"
                          {...register("location_disaster", { required: true })}
                          disabled={isLoading}
                        />
                        {errors.location_disaster && (
                          <div className="error_line">
                            Location of Disaster is required
                          </div>
                        )}
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Latitude"
                          {...register("latitude", { required: true })}
                          disabled={isLoading}
                        />
                        {errors.latitude && (
                          <div className="error_line">Latitude is required</div>
                        )}
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Longitude"
                          {...register("longitude", { required: true })}
                          disabled={isLoading}
                        />
                        {errors.longitude && (
                          <div className="error_line">
                            Longitude is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6">
                    <label className="form-label">
                      Date of Occurrence{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="date_of_occurrence"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          maxDate={new Date()}
                          placeholder="YYYY-MM-DD hh:mm a"
                          format="YYYY-MM-DD hh:mm a"
                          //format="YYYY-MM-DD hh:mm:s"
                          editable={true}
                          disabled={isLoading}
                          value={field.value || ""}
                          plugins={[
                            <TimePicker position="right" hideSeconds />,
                          ]}
                          onChange={(date) => {
                            //field.onChange(date?.format?.("YYYY-MM-DD hh:mm a"));
                            field.onChange(
                              date?.format?.("YYYY-MM-DD hh:mm:s")
                            );
                          }}
                        />
                      )}
                    />
                    {errors.date_of_occurrence && (
                      <div className="error_line">
                        Date of Occurrence is required
                      </div>
                    )}
                  </div>


                  <div className="col-12">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={addNewDamageField}
                      >
                        <FiPlus /> Add Damage Classification
                      </button>
                    </div>
                  </div>
                  {fields.map((damage, index) => {
                    return (
                      <>
                      <div className="col-12" key={damage.id}>
                        <DamageItemRow
                          level1Data={damageLevel1List}
                          index={index}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          setIsFetching={setIsFetching}
                          isEdit={isEdit}
                          damage={damage}
                        />
                      </div>




</>

                    );
                  })}




            {/* <div className="col-sm-4 col-md-4 px-0 mx-0">
                  <div className="card py-2 px-2">
                    <label className="form-label">
                    Upload Damage Image
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <div className="d-flex gap-2">
                            <div className="flex-grow-1">
                              <input
                                className="form-control"
                                type="file"
                                {...register("signature")}
                                accept=".png, .jpg, .jpeg"
                              />
                            </div>

                          </div>
                          <div className="small alert-warning m-0 alert p-2 mt-1">
                            [Allowed Format : jpg, jpeg, png]
                            <br />
                            [Max File Size : 20mb]
                          </div>
                          {errors.signature && (
                            <div className="error_line">Signature is required</div>
                          )}
                </div>
              </div> */}









                  <div className="col-lg-6 col-md-12">
                    <div className="bg-light border rounded-2 p-3 h-100">
                      <label className="form-label">
                        Deceased
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <div>
                        <YesNoRadio name="deceased" />
                      </div>
                      {errors.deceased && (
                        <div className="error_line">Deceased is required</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="bg-light border rounded-2 p-3 h-100">
                      <label className="form-label">
                        Whether Any Of The Insurance Has Been Processed Through
                        Nagaland Risk Transfer Initiative?{" "}
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <div>
                        <YesNoRadio name="risk_transfer_initiative" />
                      </div>
                      {errors.risk_transfer_initiative && (
                        <div className="error_line">
                          Risk Transfer Initiative is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="bg-light border rounded-2 p-3 h-100">
                      <label className="form-label">
                        Whether The Property Damaged Has Insurance Policy?
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <div>
                        <YesNoRadio
                          name="damaged_insurance_policy"
                          name2="damaged"
                        />
                      </div>
                      {errors.damaged_insurance_policy && (
                        <div className="error_line">
                          Damaged Insurance Policy is required
                        </div>
                      )}

                      {watch("damaged_insurance_policy") === "Yes" && (
                        <div className="mt-2">
                          <InsurancePolicy name="damaged" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="bg-light border rounded-2 p-3 h-100">
                      <label className="form-label">
                        Whether The Injured Victim Has Medical Insurance Policy?
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <div>
                        <YesNoRadio
                          name="medical_insurance_policy"
                          name2="medical"
                        />
                      </div>
                      {errors.medical_insurance_policy && (
                        <div className="error_line">
                          Medical Insurance Policy is required
                        </div>
                      )}

                      {watch("medical_insurance_policy") === "Yes" && (
                        <div className="mt-2">
                          <InsurancePolicy name="medical" />
                        </div>
                      )}
                    </div>
                  </div>
                  {watch("deceased") === "Yes" && (
                    <div className="col-lg-6 col-md-12">
                      <div className="bg-light border rounded-2 p-3 h-100">
                        <label className="form-label">
                          Whether The Deceased Victim Has Insurance Policy?
                          <span className="text-danger fw-bold">*</span>
                        </label>
                        <div>
                          <YesNoRadio
                            name="victim_insurance_policy"
                            name2="victim"
                          />
                        </div>
                        {errors.victim_insurance_policy && (
                          <div className="error_line">
                            Victim Insurance Policy is required
                          </div>
                        )}

                        {watch("victim_insurance_policy") === "Yes" && (
                          <div className="mt-2">
                            <InsurancePolicy name="victim" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-12">
                    <div className="bg-light border rounded-2 p-3 h-100">
                      <label className="form-label">
                        Whether Applicant Has Received Any Immediate Relief From
                        The Emergency Fund?
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <div>
                        <YesNoRadio name="relief_fund" name2="relief_fund" />
                      </div>
                      {/* <div className="small alert alert-warning m-0 p-1 mt-1">
                        (Should be deducted from the entitled total amount)
                      </div> */}
                      {errors.relief_fund && (
                        <div className="error_line">
                          Relief Emergency Fund is required
                        </div>
                      )}

                      {watch("relief_fund") === "Yes" && (
                        <div className="mt-2">
                          <InsurancePolicy name="relief_fund" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <label className="form-label">Comment (Optional)</label>
                    <textarea
                      type="text"
                      className="form-control"
                      {...register("comment")}
                      disabled={isLoading}
                    ></textarea>
                    {errors.comment && (
                      <div className="error_line">Comment is required</div>
                    )}
                  </div>

                  {/* <div className="col-lg-4 col-md-12">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <label className="form-label">
                          Upload Proof of Verification.{" "}
                          <span className="text-danger fw-bold">*</span>
                        </label>
                      </div>
                      {!!detailData?.Details?.application_extra_field
                        ?.application_file && (
                        <div className="flex-shrink-0 ms-3">
                          <a
                            target="_blank"
                            href={`${detailData?.Details?.application_extra_field?.application_file}`}
                            className="badge bg-primary text-white"
                            rel="noreferrer"
                          >
                            View Application File
                          </a>
                        </div>
                      )}
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      {...register("application_file", {
                        required: isEdit ? false : true,
                      })}
                      disabled={isLoading}
                    />
                    {errors.application_file && (
                      <div className="error_line">
                        Proof of Verification is required
                      </div>
                    )}
                  </div> */}

                  <div className="col-md-3">
                    <div>
                      <label className="form-label"></label>
                      <input
                        type="submit"
                        value="Next"
                        disabled={isLoading}
                        className="btn btn-primary px-5"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </DamageContext.Provider>
        </div>
      </div>

      <Modal
        show={isMapModalShow}
        onHide={mapModalClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Select Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <GoogleMap reset={reset} mapModalClose={mapModalClose} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sdrf2;
