import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiCheckCircle } from "react-icons/bi";
import GifImg from "../../../assets/images/loader.gif";
import dayjs from "dayjs";
import { BsEyeFill, BsListUl } from "react-icons/bs";
import { FaUpload } from "react-icons/fa";
import { useSelector } from "react-redux";

const SdrfSubmitted = () => {
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState({
    data: "",
    amount: "",
    img_url: "",
  });
  //getApplication
  const getApplication = async (id) => {
    try {
      const body = {
        id,
      };
      const res = await Api("view_applicant_details", "POST", body);
      // console.log(res.data);
      if (res.data.status === 1) {
        setApplicationDetail({
          data: res.data.ApplicantDetails.Details,
          amount: res.data.ApplicantDetails.amount,
          img_url: res.data.ApplicantDetails.img_url,
        });
        setIsLoaded(true);
      } else {
        // toast.error(res.data.message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        setIsLoaded(true);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getApplication

  useEffect(() => {
    getApplication(id);
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        {/* <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">SDRF Submitted</h5>
          </div>
        </div> */}
        <div className=" text-center">
          {isLoaded ? (
            applicationDetail?.data !== "" ? (
              <>
                <div className="mb-3">
                  <BiCheckCircle
                    style={{ fontSize: 100, color: "limegreen" }}
                  />
                  <h3 className="text-dark mb-4">
                    SDRF Application has been submitted
                  </h3>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-4">
                    <table className="table table-bordered table-striped m-0">
                      <tbody>
                        <tr>
                          <th>Application Id</th>
                          <td>
                            <span className="text-dark">
                              {applicationDetail?.data?.application_id}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Applicant Name</th>
                          <td>
                            <span className="text-dark">
                              {applicationDetail?.data?.applicant_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Amount</th>
                          <td>
                            <span className="text-dark">
                              Rs. {applicationDetail?.amount?.final_amount}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>State</th>
                          <td>
                            <span className="text-dark">
                              {applicationDetail?.data?.state?.state_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>District</th>
                          <td>
                            <span className="text-dark">
                              {applicationDetail?.data?.district?.district_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Submitted Date</th>
                          <td>
                            <span className="text-dark">
                              {dayjs(
                                applicationDetail?.data?.updated_at
                              ).format("DD MMM YYYY")}
                            </span>
                            <span className="text-dark ms-3">
                              {dayjs(
                                applicationDetail?.data?.updated_at
                              ).format("hh:mm a")}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-3 d-flex gap-2 justify-content-center">
                  {/* <button
                    className="btn btn-warning"
                    type="button"
                    onClick={() => {
                      navigate("/sdrf-documents", {
                        state: {
                          id: id,
                        },
                      });
                    }}
                  >
                    <FaUpload /> Upload Documents
                  </button> */}

                  {admin?.role?.permission
                    .map((word) => word.toLowerCase())
                    .some((a) => a.includes("nodal")) ? (
                    <Link className="btn btn-primary" to="/verify-applications">
                      <BsListUl className="me-2" /> Manage SDRF
                    </Link>
                  ) : (
                    <Link className="btn btn-primary" to="/manage-applications">
                      <BsListUl className="me-2" /> Manage SDRF
                    </Link>
                  )}

                  <Link
                    className="btn btn-success"
                    to={`/application/${window.btoa(
                      applicationDetail?.data?.id
                    )}`}
                  >
                    <BsEyeFill className="me-2" /> View Application
                  </Link>
                </div>
              </>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Data found</p>
              </div>
            )
          ) : (
            <div className="loaderBox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>

    </>
  );
};

export default SdrfSubmitted;
