import React, { useState, useEffect } from "react";
import Api from "../../config/Api";
import GifImg from "../../assets/images/loader.gif";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { BsPlusLg, BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import TooltipWidget from "../Components/Widget/TooltipWidget";
const ManageUser = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [userUpdate, setUserUpdate] = useState({
    id: "",
    isUpdating: false,
  });

  const modalClose = () => {
    reset({
      role: "",
      name: "",
      designation: "",
      email: "",
      phone: "",
      state: "",
      district: "",
      password: "",
      confirm_password: "",
      status: "",
    });
    setIsModalShow(false);
    setUserUpdate({ id: "", isUpdating: false });
  };
  const modalShow = () => setIsModalShow(true);

  const formatSelect = (data) => {
    return data
      .filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.district_name,
          value: item.id,
          ...item,
        };
      });
  };

  const formatSelectOption = (data) => {
    return data.map((item) => {
      return {
        label: item.district_name,
        value: item.id,
        ...item,
      };
    });
  };

  //get users
  const getUsers = async () => {
    try {
      const res = await Api("get-user");
      // console.log(res.data);
      if (res.data.status === "1") {
        setUserList(res.data.userData.reverse());
        setIsDataLoaded(true);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get users

  //get roles
  const getRoles = async () => {
    try {
      setIsLoading(true);
      const res = await Api("get-role");
      // console.log(res);
      if (res?.data?.status === 1) {
        setRoleList(res?.data?.roleDetail);
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get roles

  //get state
  const getStates = async () => {
    try {
      setIsLoading(true);
      const res = await Api("get-state");
      if (res?.data?.status === 1) {
        setStateList(res?.data?.states);
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get state

  //getDistrict
  const getDistrict = async (id) => {
    try {
      setIsLoading(true);
      const res = await Api(`get-district/${id}`);
      // console.log(res);
      if (res?.data?.status === 1) {
        setDistrictList(formatSelect(res?.data?.district));
        setIsLoading(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDistrict

  const inputChange = async (e) => {
    if (e.target.value) {
      if (e.target.name === "state") {
        getDistrict(e.target.value);
        reset({
          ...getValues(),
          district: "",
          block: "",
        });
      }
    }
  };
  //get Block

  //Update User
  const editUser = async (userObj) => {
    // console.log(userObj);
    setUserUpdate({
      id: userObj.id,
      isUpdating: true,
    });
    modalShow();
    const districtres = await getDistrict(userObj.state?.id);
    if (districtres) {
      let tempdistrict = formatSelectOption(userObj?.district);
      reset({
        name: userObj.full_name,
        designation: userObj.designation,
        email: userObj.email_id,
        phone: userObj.mobile_no,
        status: userObj.status,
        state: userObj.state?.id,
        district: tempdistrict,
        role: userObj.role?.id,
      });
    }
  };
  //Update User

  //Delete User
  const removeUser = async (userObj) => {
    try {
      if (userObj) {
        if (window.confirm("Are you sure you want to delete?") === true) {
          const body = {
            id: userObj.id,
          };
          const res = await Api("delete-user", "POST", body);
          if (res.data.status === 1) {
            toast.success("User Deleted Successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsDataLoaded(false);
            getUsers();
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete User

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "" : "",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  useEffect(() => {
    getUsers();
    getRoles();
    getStates();
  }, []);

  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      // console.log(d);
      if (userUpdate.isUpdating) {
        if (!!d?.password || !!d?.confirm_password) {
          if (!d?.password) {
            toast.error("Please Enter Password", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsLoading(false);
            return;
          }
          if (!d?.confirm_password) {
            toast.error("Please Enter Confirm Password", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsLoading(false);
            return;
          }
          if (d?.password !== d?.confirm_password) {
            toast.error("Password & Confirm Password Not Matching", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsLoading(false);
            return;
          }
        }

        let body = {
          full_name: d.name,
          designation: d.designation,
          email_id: d.email,
          phone: d.phone,
          status: Number(d.status),
          state_id: d.state,
          district_id: d.district?.map((a) => String(a.value)),
          role_id: d.role,
        };

        if (!!d.password) {
          body.password = d.password;
        }

        // console.log(body);
        const res = await Api(`update-user/${userUpdate.id}`, "POST", body);
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("User Updated Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setUserUpdate({
            id: "",
            isUpdating: false,
          });
          reset();
          modalClose();
          setIsDataLoaded(false);
          getUsers();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      } else {
        if (d?.password !== d?.confirm_password) {
          toast.error("Password & Confirm Password Not Matching", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
          return;
        }
        // console.log(d.district?.map(a => a.id));
        const body = {
          full_name: d.name,
          designation: d.designation,
          email_id: d.email,
          phone: d.phone,
          password: d.password,
          status: Number(d.status),
          state_id: d.state,
          district_id: d.district?.map((a) => String(a.value)),
          role_id: d.role,
        };
        // console.log(body);

        const res = await Api("add-user", "POST", body);
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("User Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          modalClose();
          setIsDataLoaded(false);
          getUsers();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex text-sm-start justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage User</h5>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={modalShow}
            >
              <BsPlusLg className="me-2" />
              Add User
            </button>
          </div>
        </div>
        <div>
          {isDataLoaded ? (
            userList.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-sm small table-bordered m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role Name</th>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Email</th>
                      <th>Contact</th>
                      <th style={{width:'20%'}}>District</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((item, i) => {
                      return (
                        <tr key={item.id}>
                          <td>{i + 1}</td>
                          <td>{item?.role?.role_name}</td>

                          <td>{item?.full_name}</td>
                          <td>{item?.designation}</td>
                          <td>{item?.email_id}</td>
                          <td>{item?.mobile_no}</td>
                          {/* <td>
                            {item?.role?.role_name
                              .toLowerCase()
                              .includes("admin") ||
                            item?.role?.role_name
                              .toLowerCase()
                              .includes("hod") ||
                            item?.role?.role_name
                              .toLowerCase()
                              .includes("relief")
                              ? "All Districts"
                              : item?.district
                                  ?.map((a) => a.district_name)
                                  .join(", ")}
                          </td> */}
                          <td>
                            {item?.district
                              ?.map((a) => a.district_name)
                              .join(", ")}
                          </td>
                          <td>
                            {item.status === "1" ? (
                              <span className="badge bg-success">Active</span>
                            ) : item.status === "0" ? (
                              <span className="badge bg-danger">Deactive</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <div className="d-flex gap-2 flex-wrap">
                              <TooltipWidget title="Edit">
                                <button
                                  onClick={() => editUser(item)}
                                  className="btn btn-primary btn-sm"
                                  type="button"
                                >
                                  <BsFillPencilFill />
                                </button>
                              </TooltipWidget>
                              <TooltipWidget title="Delete">
                                <button
                                  onClick={() => removeUser(item)}
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                >
                                  <BsFillTrashFill />
                                </button>
                              </TooltipWidget>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Users Added</p>
              </div>
            )
          ) : (
            <div className="loaderbox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={isModalShow}
        size="lg"
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {userUpdate.isUpdating ? "Edit User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Select Role <span className="text-danger fw-bold">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("role", { required: !userUpdate.isUpdating })}
                    disabled={isLoading}
                  >
                    <option value="">Select Role</option>
                    {roleList
                      .filter((role) => {
                        return role.status === "Active";
                      })
                      .map((role) => {
                        return (
                          <option value={role.id} key={role.id}>
                            {role.role_name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.role && (
                    <div className="error_line">Role is required</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Name <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("name", { required: !userUpdate.isUpdating })}
                    disabled={isLoading}
                  />
                  {errors.name && (
                    <div className="error_line">Name is required</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Designation <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("designation", {
                      required: !userUpdate.isUpdating,
                    })}
                    disabled={isLoading}
                  />
                  {errors.designation && (
                    <div className="error_line">Designation is required</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Email <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    {...register("email", {
                      required: {
                        value: !userUpdate.isUpdating,
                        message: "Email is required",
                      },
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid Email ID",
                      },
                    })}
                    disabled={isLoading}
                  />
                  {errors.email && (
                    <div className="error_line">{errors.email.message}</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Contact <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("phone", {
                      required: {
                        value: !userUpdate.isUpdating,
                        message: "Contact is required",
                      },
                      pattern: {
                        value: /^[6-9]\d{9}$/,
                        message: "Invalid Contact Number",
                      },
                    })}
                    disabled={isLoading}
                  />
                  {errors.phone && (
                    <div className="error_line">{errors.phone.message}</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Select State <span className="text-danger fw-bold">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("state", {
                      required: !userUpdate.isUpdating,
                      onChange: (e) => inputChange(e),
                    })}
                    disabled={isLoading}
                  >
                    <option value="">Select State</option>
                    {stateList
                      .filter((state) => {
                        return state.status === "Active";
                      })
                      .map((state) => {
                        return (
                          <option value={state.id} key={state.id}>
                            {state.state_name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.state && (
                    <div className="error_line">State is required</div>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label">
                    Select District{" "}
                    <span className="text-danger fw-bold">*</span>
                  </label>
                  <div>
                    <Controller
                      control={control}
                      name="district"
                      rules={{ required: !userUpdate.isUpdating }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={style}
                          placeholder={"Select District"}
                          options={districtList}
                          closeMenuOnSelect={true}
                          isDisabled={isLoading}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          isMulti
                        />
                      )}
                    />
                  </div>
                  {errors.district && (
                    <div className="error_line">District is required</div>
                  )}
                </div>

                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Password{" "}
                    {!userUpdate.isUpdating && (
                      <span className="text-danger fw-bold">*</span>
                    )}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    {...register("password", {
                      required: !userUpdate.isUpdating,
                    })}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  {errors.password && (
                    <div className="error_line">Password is required</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4">
                  <label className="form-label">
                    Confirm Password
                    {!userUpdate.isUpdating && (
                      <span className="text-danger fw-bold">*</span>
                    )}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    {...register("confirm_password", {
                      required: !userUpdate.isUpdating,
                    })}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  {errors.confirm_password && (
                    <div className="error_line">
                      Confirm Password is required
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-4">
                  <label className="form-label">
                    Status <span className="text-danger fw-bold">*</span>
                  </label>
                  <select
                    disabled={isLoading}
                    className="form-select"
                    {...register("status", {
                      required: !userUpdate.isUpdating,
                    })}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Deactive</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>

                <div className="col-12">
                  <div>
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {userUpdate.isUpdating ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageUser;
