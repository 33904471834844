import React, { useState, useEffect } from "react";
import { FaListUl } from "react-icons/fa";
import { TbCurrencyRupee } from "react-icons/tb";
import { BsCloudLightningRain, BsHouseDoor } from "react-icons/bs";
import Api from "../../../config/Api";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const Counters = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    // setValue,
    formState: { errors },
    // reset,
  } = useForm();

  const [counters, setCounters] = useState([]);

  const getCounters = async (dates) => {
    const body = { ...dates };
    // console.log(body);
    const { data } = await Api("dashboard", "POST", body);
    // console.log(data.data);
    if (data.status === 1) {
      setCounters(data.data);
    }
  };

  useEffect(() => {
    let body = {
      start_date: "",
      end_date: "",
    };
    if (dayjs().format("M") > 2) {
      body.start_date = dayjs("1 3", "D M").format("YYYY-MM-DD");
      body.end_date = dayjs("2", "M")
        .add(1, "year")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      body.start_date = dayjs("1 3", "D M")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      body.end_date = dayjs("2", "M").endOf("month").format("YYYY-MM-DD");
    }
    getCounters(body);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    let body = {
      start_date: "",
      end_date: "",
    };
    if (data.count_date_range === "Current Financial Year") {
      if (dayjs().format("M") > 2) {
        body.start_date = dayjs("1 3", "D M").format("YYYY-MM-DD");
        body.end_date = dayjs("2", "M")
          .add(1, "year")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        body.start_date = dayjs("1 3", "D M")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        body.end_date = dayjs("2", "M").endOf("month").format("YYYY-MM-DD");
      }
    } else if (data.count_date_range === "Daily") {
      body.start_date = dayjs().format("YYYY-MM-DD");
      body.end_date = dayjs().format("YYYY-MM-DD");
    } else if (data.count_date_range === "Weekly") {
      body.start_date = dayjs().format("YYYY-MM-DD");
      body.end_date = dayjs().format("YYYY-MM-DD");
    } else if (data.count_date_range === "Current Month") {
      body.start_date = dayjs().startOf("month").format("YYYY-MM-DD");
      body.end_date = dayjs().endOf("month").format("YYYY-MM-DD");
    } else if (data.count_date_range === "Previous Month") {
      body.start_date = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      body.end_date = dayjs()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (data.count_date_range === "Custom Dates") {
      body.start_date = dayjs(data?.count_start_date, "DD MMM YYYY").format(
        "YYYY-MM-DD"
      );
      body.end_date = dayjs(data?.count_end_date, "DD MMM YYYY").format(
        "YYYY-MM-DD"
      );
    } else {
      if (dayjs().format("M") > 2) {
        body.start_date = dayjs("1 3", "D M").format("YYYY-MM-DD");
        body.end_date = dayjs("2", "M")
          .add(1, "year")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        body.start_date = dayjs("1 3", "D M")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        body.end_date = dayjs("2", "M").endOf("month").format("YYYY-MM-DD");
      }
    }
    getCounters(body);
  };

  return (
    <div className="">
      <div className="mb-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-2">
            <div className="col-12 col-lg-auto text-lg-end">
              <label className="m-0 fw-bold py-lg-1 small">Filters:</label>
            </div>
            <div className="col-lg-2 col-md col-sm-4">
              <div className="">
                <select
                  {...register("count_date_range")}
                  className="form-select form-select-sm"
                >
                  <option value="Current Financial Year">
                    Current Financial Year
                  </option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Current Month">Current Month</option>
                  <option value="Previous Month">Previous Month</option>
                  <option value="Custom Dates">Custom Dates</option>
                </select>
              </div>
            </div>
            {watch("count_date_range") === "Custom Dates" && (
              <>
                <div className="col-lg-2 col-md col-sm-4 col-6">
                  <Controller
                    control={control}
                    name="count_start_date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputClass="form-control form-control-sm"
                        maxDate={new Date()}
                        placeholder="Select Start Date"
                        format="DD MMM YYYY"
                        editable={true}
                        value={field.value || ""}
                        onChange={(date) => {
                          field.onChange(date?.format?.("DD MMM YYYY"));
                        }}
                      />
                    )}
                  />
                  {errors.count_start_date && (
                    <div className="error_line">Start date is required</div>
                  )}
                </div>
                <div className="col-lg-2 col-md col-sm-4 col-6">
                  <Controller
                    control={control}
                    name="count_end_date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputClass="form-control form-control-sm"
                        maxDate={new Date()}
                        minDate={
                          dayjs(watch("count_start_date"), "DD MMM YYYY").$d
                        }
                        placeholder="Select End Date"
                        format="DD MMM YYYY"
                        editable={true}
                        value={field.value || ""}
                        onChange={(date) => {
                          field.onChange(date?.format?.("DD MMM YYYY"));
                        }}
                      />
                    )}
                  />
                  {errors.count_end_date && (
                    <div className="error_line">End date is required</div>
                  )}
                </div>
              </>
            )}
            <div className="col-auto">
              <div className="">
                <input
                  type="submit"
                  value="Show"
                  className="btn btn-primary btn-sm px-4"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="">
        <div className="row g-3 counterboxrow">
          <div className="col-lg-5 col-xl-4">
            <div className="counterbox">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="fw-bold text-dark">SDRF Count</h6>
                </div>
                <div className="flex-shrink-0 ms-3 counterboxicon">
                  <FaListUl />
                </div>
              </div>
              <div className="mt-2">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <table className="table table-borderless m-0 table-sm small">
                      <tbody>
                        <tr>
                          <td>Pending</td>
                          <th className="text-end" style={{ width: "50px" }}>
                            {!!counters?.pending ? counters?.pending : 0}
                          </th>
                        </tr>

                        <tr>
                          <td>Verified</td>
                          <th className="text-end">
                            {!!counters?.verified ? counters?.verified : 0}
                          </th>
                        </tr>

                        <tr>
                          <td>Approved</td>
                          <th className="text-end">
                            {!!counters?.approved ? counters?.approved : 0}
                          </th>
                        </tr>

                        <tr>
                          <td>Rejected</td>
                          <th className="text-end">
                            {!!counters?.rejected ? counters?.rejected : 0}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flex-grow-1">
                    <table className="table table-borderless m-0 table-sm small">
                      <tbody>
                        <tr>
                          <td>DPA Review</td>
                          <th className="text-end" style={{ width: "50px" }}>
                            {!!counters?.dpa_review ? counters?.dpa_review : 0}
                          </th>
                        </tr>

                        <tr>
                          <td>Nodal Officer Review</td>
                          <th className="text-end">
                            {!!counters?.nodal_officer_review
                              ? counters?.nodal_officer_review
                              : 0}
                          </th>
                        </tr>

                        <tr>
                          <td>Final Approved</td>
                          <th className="text-end">
                            {!!counters?.final_approved
                              ? counters?.final_approved
                              : 0}
                          </th>
                        </tr>

                        <tr>
                          <td>Released</td>
                          <th className="text-end">
                            {!!counters?.released ? counters?.released : 0}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg col-sm-4">
            <div className="counterbox d-flex justify-content-center align-items-center">
              <div className="text-center">
                <div className="counterboxicon mb-3 h1">
                  <TbCurrencyRupee />
                </div>
                <div className="">
                  <h6 className="mb-2 text-dark">Fund Released</h6>
                  <div className="fw-bold h5 text-dark">
                    ₹{" "}
                    {!!counters?.total_released_amount
                      ? counters?.total_released_amount
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg col-sm-4">
            <div className="counterbox  d-flex justify-content-center align-items-center">
              <div className="py-2 text-center">
                <div className="counterboxicon mb-3 h1">
                  <BsCloudLightningRain />
                </div>
                <div className="">
                  <h6 className="mb-2 text-dark">Disaster Count</h6>
                  <div className="fw-bold h5 text-dark">
                    {!!counters?.nature_disaster
                      ? counters?.nature_disaster
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg col-sm-4">
            <div className="counterbox  d-flex justify-content-center align-items-center">
              <div className="py-2 text-center">
                <div className="counterboxicon mb-3 h1">
                  <BsHouseDoor />
                </div>
                <div className="">
                  <h6 className="mb-2 text-dark">Property Count</h6>
                  <div className="fw-bold h5 text-dark">
                    {!!counters?.second_damage ? counters?.second_damage : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counters;
