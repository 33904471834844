import React, { useEffect, useState, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Api from "../../../../config/Api";
import { toast } from "react-toastify";
import Select from "react-select";
import { DamageContext } from "../Sdrf2";
import { FaFileDownload, FaQuestionCircle } from "react-icons/fa";
// import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Tooltip from "react-bootstrap/Tooltip";
const DamageItemRow = (props) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const {
    level1Data,
    damage,
    index,
    isLoading,
    setIsLoading,
    setIsFetching,
    isEdit,
  } = props;
  console.log("damage", damage?.damage_image);
  // const [damageLevel1List, setDamageLevel1List] = useState([]);
  const [damageLevel2List, setDamageLevel2List] = useState([]);
  const [damageLevel3List, setDamageLevel3List] = useState([]);
  const [isThirdDropdownShow, setiIThirdDropdownShow] = useState(false);

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#fff" : "#fff",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  // const level1Option = (data) => {
  //   return data
  //     ?.filter((d) => d.status === "Active")
  //     .map((item) => {
  //       return {
  //         label: item.title,
  //         value: item.id,
  //       };
  //     });
  // };
  const level2Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.damage_name,
          value: item.id,
          ...item,
        };
      });
  };

  const level3Option = (data) => {
    // console.log(data);
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: !!item?.second_damage?.extent_unit
            ? `${item.name} (${item?.second_damage?.extent_unit})`
            : item.name,
          value: item.id,
          ...item,
        };
      });
  };

  //damage level 1
  // const getlevel1 = async () => {
  //   try {
  //     setIsLoading(true);
  //     const res = await Api("first_damage_classification_get");
  //     // console.log(res);
  //     if (res.data.status === "1") {
  //       setDamageLevel1List(level1Option(res.data.DamageClassificationData));
  //       setIsLoading(false);
  //     } else {
  //       toast.error(res?.data?.message, {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //       });
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     toast.error(error.data.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //   }
  // };
  //damage level 1

  //damage level 2
  const getlevel2 = async (id) => {
    try {
      if (!isEdit) {
        setIsFetching(true);
        setIsLoading(true);
      }
      const res = await Api(`second_damage_classification_get/${id}`);
      // console.log(res);
      if (res.data.status === 1) {
        setDamageLevel2List(level2Option(res.data.DamageClassificationData));
        setIsLoading(false);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 2

  //damage level 3
  const getlevel3 = async (id, ind) => {
    setiIThirdDropdownShow(false);
    setValue(`damages.${ind}.third_damage_classification_id`, "");
    try {
      if (!isEdit) {
        setIsFetching(true);
        setIsLoading(true);
      }
      const res = await Api(`third_damage_classification_get/${id}`);

      if (res.data.status === 1) {
        setDamageLevel3List(level3Option(res.data.data));
        if (res.data.data.length > 1) {
          setiIThirdDropdownShow(true);
        } else {
          // console.log(res.data.data[0]);

          let tempData = {
            label: res.data.data[0].name,
            value: res.data.data[0].id,
            ...res.data.data[0],
          };

          setValue(`damages.${ind}.third_damage_classification_id`, tempData);
        }
        setIsLoading(false);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 3

  //deleteHandler
  const { remove } = useContext(DamageContext);
  const deleteHandler = (i) => {
    remove(i);
  };
  //deleteHandler

  useEffect(() => {
    if (isEdit) {
      (async () => {
        let res1;
        let res2;
        if (!!damage?.first_damage_classification_id?.value) {
          res1 = await getlevel2(damage?.first_damage_classification_id?.value);
        }
        if (!!damage?.second_damage_classification_id?.value) {
          res2 = await getlevel3(
            damage?.second_damage_classification_id?.value
          );
        }
        if (res1 && res2) {
          setIsFetching(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    // getlevel1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <>
        [Allowed Format : jpg, jpeg, png, pdf]
        <br />
        [Max File Size : 20mb]
      </>
    </Tooltip>
  );

  return (
    <div className="damagerow_div" style={{ padding: "40px 20px" }}>
      <div className="row g-2">
        <div className="col-sm-6 col-md-4">
          <label className="form-label">
            Item Damaged
            <span className="text-danger fw-bold">*</span>
          </label>
          <Controller
            control={control}
            rules={{ required: true }}
            name={`damages.${index}.first_damage_classification_id`}
            onFocus={true}
            render={({ field }) => (
              <Select
                {...field}
                autoFocus={field.ref}
                isDisabled={isLoading}
                styles={style}
                placeholder={"Select Item Damaged"}
                options={level1Data}
                onChange={(e) => {
                  field.onChange(e);
                  getlevel2(e.value);
                  setValue(
                    `damages.${index}.second_damage_classification_id`,
                    ""
                  );
                  setValue(
                    `damages.${index}.third_damage_classification_id`,
                    ""
                  );
                  setiIThirdDropdownShow(false);
                  setDamageLevel3List([]);
                }}
              />
            )}
          />

          {errors &&
            errors.damages &&
            errors.damages?.[index]?.first_damage_classification_id && (
              <div className="error_line"> Item Damaged is required</div>
            )}
        </div>
        <div className="col-sm-6 col-md-4">
          <label className="form-label">
            Category
            <span className="text-danger fw-bold">*</span>
          </label>
          <Controller
            control={control}
            rules={{ required: true }}
            name={`damages.${index}.second_damage_classification_id`}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={isLoading}
                styles={style}
                placeholder={"Select Category"}
                options={damageLevel2List}
                onChange={(e) => {
                  field.onChange(e);
                  getlevel3(e.value, index);
                }}
              />
            )}
          />
          {errors &&
            errors.damages &&
            errors.damages?.[index]?.second_damage_classification_id && (
              <div className="error_line"> Category is required</div>
            )}
        </div>

        <div
          className={`col-sm-6 col-md-4 ${
            isThirdDropdownShow ? "d-block" : "d-none"
          }`}
        >
          <label className="form-label">
            Sub Category
            <span className="text-danger fw-bold">*</span>
          </label>
          <Controller
            control={control}
            rules={{ required: true }}
            name={`damages.${index}.third_damage_classification_id`}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={isLoading}
                styles={style}
                placeholder={"Select Sub Category"}
                options={damageLevel3List}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          {errors &&
            errors.damages &&
            errors.damages?.[index]?.third_damage_classification_id && (
              <div className="error_line">Sub Category is required</div>
            )}
        </div>

        {!!watch(`damages.${index}.third_damage_classification_id`).min &&
          !!watch(`damages.${index}.third_damage_classification_id`).max && (
            <div className="col-sm-6 col-md-4">
              <div className="">
                <label className="form-label">
                  Qty <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register(`damages.${index}.qty`, {
                    required: "Qty is required",
                    min: {
                      value: watch(
                        `damages.${index}.third_damage_classification_id`
                      ).min,
                      message: `Minimum limit is ${
                        watch(`damages.${index}.third_damage_classification_id`)
                          .min
                      }`,
                    },
                    max: {
                      value: watch(
                        `damages.${index}.third_damage_classification_id`
                      ).max,
                      message: `Maximum limit is ${
                        watch(`damages.${index}.third_damage_classification_id`)
                          .max
                      }`,
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Invalid number",
                    },
                  })}
                  disabled={props.isLoading}
                />
                {errors && errors.damages && errors.damages?.[index]?.qty && (
                  <div className="error_line">
                    {errors?.damages?.[index]?.qty?.message}
                  </div>
                )}
              </div>
            </div>
          )}

        {!!watch(`damages.${index}.first_damage_classification_id`) &&
          !!watch(`damages.${index}.first_damage_classification_id`).label &&
          watch(
            `damages.${index}.first_damage_classification_id`
          ).label.toLowerCase() === "housing" && (
            <div className="col-sm-6 col-md-4">
              <div className="">
                <label className="form-label">
                  House Owner Type{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <select
                  className="form-select"
                  {...register(`damages.${index}.owner_type`, {
                    required: true,
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select House Owner Type</option>
                  <option value="Owner">Owner</option>
                  <option value="Tenant">Tenant</option>
                </select>
                {errors &&
                  errors.damages &&
                  errors.damages?.[index]?.owner_type && (
                    <div className="error_line">
                      House Owner Type is required
                    </div>
                  )}
              </div>
            </div>
          )}

        <div className="col-lg-4 col-md-4">
          <div className="">
            <label className="form-label d-flex justify-content-between">
              {/* <span>
                  Upload damage images{" "}
                  <span className="text-danger fw-bold">*</span>
                </span> */}
              {!!watch(`damages.${index}.first_damage_classification_id`) &&
              !!watch(`damages.${index}.first_damage_classification_id`)
                .label &&
              watch(
                `damages.${index}.first_damage_classification_id`
              ).label.toLowerCase() === "human" ? (
                <span>
                  Upload Certificate (Medical, Death & Succession) <span className="text-danger fw-bold">*</span>
                </span>
              ) : (
                <span>
                  Damage Image <span className="text-danger fw-bold">*</span>
                </span>
              )}
              <span>
                {damage?.damage_image && (
                  <input
                    type="hidden"
                    {...register(`damages.${index}.damage_old_image`)}
                    value={damage?.damage_image}
                  />
                )}
              </span>
              <span>
                {damage?.damage_image && (
                  <a
                    rel="noreferrer"
                    href={damage?.damage_image}
                    className="badge bg-primary border-0 mx-3"
                    target="_blank"
                  >
                    View Image
                  </a>
                )}

                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span class="text-warning">
                    <FaQuestionCircle />
                  </span>
                </OverlayTrigger>
              </span>
            </label>
            {/* <div className="bg-light p-3 rounded mb-1 text-center">
                              {selectedFiles["damages[]"].length > 0 ? (
                                <div className="d-flex flex-wrap gap-3">
                                  {selectedFiles["damages[]"].map((a, i) => {
                                    return (
                                      <div className="damage_images_box" key={i}>
                                        <img
                                          src={window.URL.createObjectURL(a)}
                                          alt="Damage"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : damage?.length > 0 ? (
                                <div className="d-flex flex-wrap gap-3">
                                  {damage.map((a, i) => {
                                    return (
                                      <div className="damage_images_box" key={i}>
                                        <img
                                          src={`${damage?.uploads_document_url}${a}`}
                                          alt="Damage"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <img
                                  src="https://via.placeholder.com/300x100?text=Damage%20images"
                                  className="img-fluid"
                                  alt="Damage"
                                />
                              )}
                            </div> */}
            <input
              className="form-control"
              type="file"
              accept=".png, .jpg, .jpeg, .pdf"
              {...register(`damages.${index}.image`, {
                required: Boolean(damage?.damage_image) ? false : true,
                // onChange: (e) =>
                //   uploadInputFiles(e, [".png", ".jpg", ".jpeg"]),
              })}
            />
            {errors && errors.damages && errors.damages?.[index]?.image && (
              <div className="error_line">Damage images is required</div>
            )}
          </div>
        </div>

        {index > 0 && (
          <div className="col-sm-4">
            <div className="">
              <label className="form-label"></label>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteHandler(index)}
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DamageItemRow;
