import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import GifImg from "../../assets/images/loader.gif";
import Select from "react-select";
import Pagination from "react-bootstrap/Pagination";
import TooltipWidget from "../Components/Widget/TooltipWidget";

const Category = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const [damageListCategory, setDamageListCategory] = useState([]);
  const [damageList, setDamageList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [damageUpdate, setDamageUpdate] = useState({
    id: "",
    isUpdating: false,
  });
  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "" : "#fbfbfb",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  const damageModalClose = () => {
    reset({
      first_damage_classification_id: "",
      damage_name: "",
      extent_unit: "",
      status: "",
      // input_type: "",
      // multiselect: "",
      // option_values: "",
      // min: "",
      // max: "",
      // allow_qty: "",
    });
    setModalShow(false);
    setDamageUpdate({ id: "", isUpdating: false });
  };

  const damageModalShow = () => setModalShow(true);

  const categoryListOption = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
  };

  //get damages
  const getItemDamages = async () => {
    try {
      setIsLoading(true);
      const res = await Api("first_damage_classification_get");
      // console.log(res);
      if (res.data.status === "1") {
        setDamageListCategory(
          categoryListOption(res.data.DamageClassificationData)
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const getCategory = async (pageCount) => {
    try {
      const res = await Api(
        `second_damage_classification_get?page=${pageCount}&search=${searchItem}`
      );
      // console.log(res);
      if (res.data.status === 1) {
        setDamageList(res.data.DamageClassificationData);
        setIsDataLoaded(true);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get damages

  //Update Damage
  const editDamage = async (damageObj) => {
    // console.log(damageObj);
    setDamageUpdate({
      id: damageObj.id,
      isUpdating: true,
    });
    damageModalShow();
    reset({
      first_damage_classification_id: {
        label: damageObj.first_damage.title,
        value: damageObj.first_damage.id,
      },
      damage_name: damageObj.damage_name,
      extent_unit: damageObj.extent_unit,
      status: damageObj.status,
      // input_type: damageObj.input_type,
      // multiselect: damageObj.multiselect,
      // option_values: damageObj.option_values,
      // min: damageObj.min,
      // max: damageObj.max,
      // allow_qty: damageObj.allow_qty,
    });
  };
  //Update Damage

  //Delete Damage
  const removeDamage = async (damageObj) => {
    try {
      if (damageObj) {
        if (window.confirm("Are you sure you want to delete?") === true) {
          const body = {
            id: damageObj.id,
          };
          const res = await Api(
            "second_damage_classification_delete",
            "POST",
            body
          );
          // console.log(res);
          setIsDataLoaded(false);
          if (res.data.status === "1") {
            toast.success("Category Deleted Successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            getCategory();
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete Damage

  useEffect(() => {
    getItemDamages();
    getCategory(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //pagination count
  // const IndexCount = ({ index }) => {
  //   let cp = damageList.current_page;
  //   let pp = damageList.per_page;
  //   let count;
  //   if (cp > 1) {
  //     count = (cp - 1) * pp + index;
  //   } else {
  //     count = index;
  //   }
  //   return count;
  // };
  //pagination count

  const handleInputChange = (e) => {
    setSearchItem(e.target.value);
  };

  // const filteredItems = !searchItem
  //   ? damageList?.data
  //   : damageList?.data.filter((damage) =>
  //       damage?.first_damage?.title
  //         .toLowerCase()
  //         .includes(searchItem.toLowerCase()) ||
  //       damage?.damage_name.toLowerCase().includes(searchItem.toLowerCase()) ||
  //       damage?.extent_unit.toLowerCase().includes(searchItem.toLowerCase())
  //     );

  //pagination
  const PaginationRow = () => {
    return (
      <div className="mt-4 d-flex justify-content-md-center overflow-auto">
        <Pagination>
          {damageList?.links?.map((item, i) => {
            if (i === 0) {
              return (
                <Pagination.Prev
                  key={i}
                  onClick={() => getCategory(damageList?.current_page - 1)}
                  disabled={damageList?.current_page === 1 ? true : false}
                />
              );
            } else if (item.label === "...") {
              return <Pagination.Ellipsis key={i} />;
            } else if (i + 1 === damageList?.links.length) {
              return (
                <Pagination.Next
                  key={i}
                  onClick={() => getCategory(damageList?.current_page + 1)}
                  disabled={
                    damageList?.current_page === damageList?.last_page
                      ? true
                      : false
                  }
                />
              );
            } else if (i !== damageList?.links.length) {
              return (
                <Pagination.Item
                  key={i}
                  active={Number(item.label) === damageList?.current_page}
                  onClick={() => getCategory(item.label)}
                >
                  {item?.label}
                </Pagination.Item>
              );
            } else {
              return false;
            }
          })}
        </Pagination>
      </div>
    );
  };
  //pagination

  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      // console.log(d);
      if (damageUpdate.isUpdating) {
        let body = {
          first_damage_classification_id:
            d.first_damage_classification_id.value,
          damage_name: d.damage_name,
          extent_unit: d.extent_unit,
          status: d.status,
          // input_type: d.input_type,
          // multiselect: d.multiselect,
          // option_values: d.option_values,
          // min: d.min,
          // max: d.max,
          // allow_qty: d.allow_qty,
        };
        const res = await Api(
          `second_damage_classification_update/${damageUpdate.id}`,
          "POST",
          body
        );
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Category Updated Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setDamageUpdate({
            id: "",
            isUpdating: false,
          });
          reset();
          damageModalClose();
          setIsDataLoaded(false);
          getCategory();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      } else {
        let body = {
          first_damage_classification_id:
            d.first_damage_classification_id.value,
          damage_name: d.damage_name,
          extent_unit: d.extent_unit,
          status: d.status,
          // input_type: d.input_type,
          // multiselect: d.multiselect,
          // option_values: d.option_values,
          // min: d.min,
          // max: d.max,
          // allow_qty: d.allow_qty,
        };
        const res = await Api("second_damage_classification_add", "POST", body);
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Category Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          damageModalClose();
          setIsDataLoaded(false);
          getCategory();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-sm-flex flex-warp gap-2 justify-content-between mb-3">
          <div className="mb-2 mb-sm-0">
            <h5 className="text-dark">Category</h5>
          </div>
          <div className="">
            <div className="d-flex flex-wrap align-items-center gap-2">
              <div>
                <input
                  type="text"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder="Type to search"
                  className="form-control"
                />
              </div>
              <button type="button" onClick={getCategory} className="btn btn-primary">Search</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={damageModalShow}
              >
                <BsPlusLg className="me-2" />
                Add Category
              </button>
            </div>
          </div>
        </div>

        {isDataLoaded ? (
          damageList?.data?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead>
                  <tr>
                    <th>Item Damaged</th>
                    <th>Category</th>
                    <th>Extend Unit</th>
                    {/* <th>Input Type</th>
                    <th>Option Values</th>
                    <th>Multiselect</th>
                    <th>Min - Max</th> */}
                    {/* <th>Allow Qty</th> */}
                    <th>Status</th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {damageList?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item?.first_damage?.title}</td>
                        <td>{item?.damage_name}</td>
                        <td>{item?.extent_unit}</td>
                        {/* <td>{item?.input_type}</td>
                        <td>{item?.option_values}</td>
                        <td>
                          {item?.multiselect === "1"
                            ? "Yes"
                            : item?.multiselect === "2"
                            ? "No"
                            : "-"}
                        </td>

                        <td>
                          {item?.min} - {item?.max}
                        </td> */}
                        {/* <td>
                          {item?.allow_qty === "1"
                            ? "Yes"
                            : item?.allow_qty === "2"
                            ? "No"
                            : "-"}
                        </td> */}
                        <td>
                          {item.status === "Active" ? (
                            <span className="badge bg-success">Active</span>
                          ) : item.status === "Deactive" ? (
                            <span className="badge bg-danger">Deactive</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2 flex-wrap">
                            <TooltipWidget title="Edit">
                              <button
                                onClick={() => editDamage(item)}
                                className="btn btn-primary btn-sm"
                                type="button"
                              >
                                <BsFillPencilFill />
                              </button>
                            </TooltipWidget>
                            <TooltipWidget title="Delete">
                              <button
                                onClick={() => removeDamage(item)}
                                className="btn btn-danger btn-sm"
                                type="button"
                              >
                                <BsFillTrashFill />
                              </button>
                            </TooltipWidget>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {damageList?.last_page > 1 && <PaginationRow />}
            </div>
          ) : (
            <div className="alert alert-danger m-0">
              <p>No Category Added</p>
            </div>
          )
        ) : (
          <div className="loaderbox">
            <img src={GifImg} alt="Loader" />
            <p>Loading...</p>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        onHide={damageModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {damageUpdate.isUpdating ? "Update" : "Add"} Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-sm-12">
                  <label className="form-label">
                    Item Damaged <span className="fw-bold text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="first_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Item Damaged"}
                        options={damageListCategory}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.first_damage_classification_id && (
                    <div className="error_line">Damage Type is required</div>
                  )}
                </div>
                <div className="col-sm-12">
                  <label className="form-label">
                    Category <span className="fw-bold text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("damage_name", { required: true })}
                    disabled={isLoading}
                  />
                  {errors.damage_name && (
                    <div className="error_line">Damage Name is required</div>
                  )}
                </div>
                <div className="col-sm-6">
                  <label className="form-label">Extend Unit</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("extent_unit")}
                    disabled={isLoading}
                  />
                  {errors.extent_unit && (
                    <div className="error_line">Extent Unit is required</div>
                  )}
                </div>
                {/*
                <div className="col-sm-6">
                  <label className="form-label">Input Type</label>
                  <select
                    className="form-select"
                    {...register("input_type", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Input Type</option>
                    <option value="Text">Input Text</option>
                    <option value="Select">Select</option>
                    <option value="Textarea">Textarea</option>
                    <option value="Checkbox">Checkbox</option>
                    <option value="Radio">Radio</option>
                    <option value="File">File Upload</option>
                    <option value="Date">Date</option>
                    <option value="Time">Time</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.input_type && (
                    <div className="error_line">Input Type is required</div>
                  )}
                </div>
                {(watch("input_type") === "Select" ||
                  watch("input_type") === "Checkbox" ||
                  watch("input_type") === "Radio" ||
                  watch("input_type") === "File") && (
                  <>
                    <div className="col">
                      <label className="form-label">Multiselect</label>
                      <select
                        className="form-select"
                        {...register("multiselect", { required: true })}
                        disabled={isLoading}
                      >
                        <option value="">Select Multiselect</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                      {errors.multiselect && (
                        <div className="error_line">
                          Multiselect is required
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <label className="form-label">Option Values</label>
                      <textarea
                        className="form-control"
                        style={{ height: 100 }}
                        {...register("option_values", { required: true })}
                        disabled={isLoading}
                      ></textarea>
                      {errors.option_values && (
                        <div className="error_line">
                          Option Values is required
                        </div>
                      )}
                      <div className="mt-2 small">
                        <p className="alert alert-warning p-2">
                          Use '|' to seprate values & '-' for each value amount,
                          <br /> e.g. <b>1.0-1000|1.1-1100</b>
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-6">
                  <label className="form-label">Allow Qty</label>
                  <select
                    className="form-select"
                    {...register("allow_qty", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Qty</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                  {errors.allow_qty && (
                    <div className="error_line">Allow Qty is required</div>
                  )}
                </div>
                {watch("allow_qty") === "1" && (
                  <>
                    <div className="col">
                      <label className="form-label">Min</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("min", { required: true })}
                        disabled={isLoading}
                      />
                      {errors.min && (
                        <div className="error_line">Min is required</div>
                      )}
                    </div>
                    <div className="col">
                      <label className="form-label">Max</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("max", { required: true })}
                        disabled={isLoading}
                      />
                      {errors.max && (
                        <div className="error_line">Max is required</div>
                      )}
                    </div>
                  </>
                )} */}
                <div className="col-sm-6">
                  <label className="form-label">
                    Status <span className="fw-bold text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("status", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>

                <div className="col-12">
                  <div>
                    <input
                      type="submit"
                      value={damageUpdate.isUpdating ? "Update" : "Submit"}
                      className="btn btn-primary"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Category;
