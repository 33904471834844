import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import GifImg from "../../../assets/images/loader.gif";
// import InfiniteScroll from "react-infinite-scroller";
// import qs from "qs";
import { useForm } from "react-hook-form";
import {  FaCheck } from "react-icons/fa";
import { BsEyeFill } from "react-icons/bs";
import StatusBadge from "../Application/Components/StatusBadge";
import ModalBox from "../../Components/Widget/ModalBox";
import Table from "../../Components/Widget/Table";
import Loader from "../../Components/Widget/Loader";
import TooltipWidget from "../../Components/Widget/TooltipWidget";

const ReliefApproveApplications = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // const ImgUrl = "https://cdn-icons-png.flaticon.com/512/219/219983.png";
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  // const [currentStatus, setCurrentStatus] = useState(3);
  const [filter, setFilter] = useState("");
  const [selectedData, setSelectedData] = useState({
    id: "",
    status: "",
  });
  const [isModalShow, setIsModalShow] = useState(false);
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));

  const modalClose = () => {
    setIsModalShow(false);
    reset();
    setSelectedData({
      id: "",
      status: "",
    });
  };
  const modalShow = (id, status) => {
    setIsModalShow(true);
    setSelectedData({
      id,
      status,
    });
  };

  //Application Get
  const getApplication = async (status_id) => {
    try {
      setIsLoading(true);
      const body = {
        status: status_id,
      };
      const res = await Api("application_list", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };

  const filtered = !filter
    ? application
    : application.filter(
        (application) =>
          application?.application_id
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.applicant_name
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.mobile?.toLowerCase().includes(filter.toLowerCase()) ||
          application?.district?.district_name?.toLowerCase().includes(filter.toLowerCase())
      );

  useEffect(() => {
    getApplication([3]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!admin) {
      reset({
        name: admin.full_name,
      });
    }
    // eslint-disable-next-line
  }, [reset]);

  const onSubmit = async (d) => {
    // console.log(d);
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", selectedData.id);

      formData.append("name", d?.name);
      formData.append("remark", d?.remark);
      formData.append("status", d?.status);
      if (d?.document.length > 0) {
        formData.append("document", d?.document[0]);
      }

      // console.log(Object.fromEntries(formData));

      const res = await Api("application_approve", "POST", formData);
      // console.log(res);

      if (res.data.status === 1) {
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        modalClose();
        getApplication([3]);
        setIsLoading(false);
      } else if (res.data.status === 2) {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        modalClose();
        getApplication([3]);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
    },
    {
      name: "Application ID",
      selector: (row) => row.application_id,
      sortable: true,
      wrap: true,
    },
    {
    //   name: "Applicant Image",
    name: "Applicant Passport Photo",
      selector: (row) => (
        <img
          src={row.applicant_default_img}
          alt="Applicant"
          style={{
            height: "70px",
            width: "100px",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      name: "Applicant Info",
      selector: (row) => (
        <>
          <div className="mb-1">{row.applicant_name}</div>
          <div>{row.mobile}</div>
        </>
      ),
    },
    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => <StatusBadge status={row.status} />,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-1">
          <TooltipWidget title="View SDRF">
            <Link
              className="btn btn-warning btn-sm"
            //   target="_blank"
              to={`/application/${window.btoa(row.id)}`}
            >
              <BsEyeFill />
            </Link>
          </TooltipWidget>
          <TooltipWidget title="Approve/Review/Reject SDRF">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                modalShow(row.id, "3");
              }}
            >
              <FaCheck />
            </button>
          </TooltipWidget>
        </div>
      ),
      minWidth: "180px",
    },
  ];

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="row g-2">
            <div className="col-lg-6 col-md-4">
              <h5 className="text-dark">Approve Applications</h5>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="">
                <input
                  type="text"
                  placeholder="Search Application"
                  className="form-control"
                  value={filter}
                  onChange={searchInput}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={filtered} />
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <ModalBox show={isModalShow} hide={modalClose} title="Approve SDRF">
        <div className="p-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              {/* <div className="col-12">
                <div className="alert alert-warning m-0 p-2 fw-bold">
                  Are you sure you want to Approve this SDRF Form?
                </div>
              </div> */}
              <div className="col-12">
                <div className="">
                  <label>
                    Name
                    <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name", {
                      required: true,
                    })}
                    disabled={true}
                  />
                  {errors.name && (
                    <div className="error_line">Name is required</div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="">
                  <label>
                    Comment/Remark
                    <span className="text-danger fw-bold">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    {...register("remark", {
                      required: true,
                    })}
                    rows={5}
                  ></textarea>
                  {errors.remark && (
                    <div className="error_line">Comment/Remark is required</div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="">
                  <label>Upload supporting file (if any)</label>
                  <input
                    className="form-control"
                    type="file"
                    {...register("document")}
                  />
                  {errors.document && (
                    <div className="error_line">
                      Supporting file is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="">
                  <label>
                    Select Status <span className="text-danger fw-bold">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("status", {
                      required: true,
                    })}
                  >
                    <option value="">Select Status</option>
                    <option value="4">Nodal Officer Review</option>
                    <option value="5">Approve</option>
                    <option value="7">Reject</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex gap-2">
                  <button
                    type="submit"
                    className="btn btn-primary px-5"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
    </>
  );
};

export default ReliefApproveApplications;
